* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.contact {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.contact {
  background-image: url('../public/images/contactbackground.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
}

.contact-container {
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  border-radius: 30px;
  background: #3a3a3c;
  color: #fff;
  inline-size: min-content;
}

.contact-container > h1 {
  color: #fff;
  font-size: 2.2rem;
  text-align: center;
  padding: 1rem;
}

.contact-container > div {
  font-size: 1.2rem;
  text-align: center;
  padding: 1rem;
}
