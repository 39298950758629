video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.stablemain-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.stablemain-btns {
  margin-bottom: 650px;
  position: fixed;
}

@media screen and (max-width: 989px) {
  .stablemain-container {
    background-image: url('../../public/images/conorMitchMobile.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    justify-content: center;
  }

  .stablemain-btns {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    display: flex;
    margin-right: 20px;
  }

  .stablemain-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }

  .spot-image {
    max-width: 75%;
    border-radius: 10px;
  }
}
